import React, { useEffect } from "react";
import { Form, Select, Button, message } from "antd";
import { useAPIRequest } from "@helpers/hooks";
import {
  get_all_teams_for_sent_to_next_level,
  send_to_next_level_mutation,
} from "@services/graphql/event_schedule_team";
import { useDynamicSelector } from "@services/redux";
import { get_filtered_schedules_query } from "@services/graphql/performance";

const { Option } = Select;

const SentTeamNextLevel = ({
  event_schedule_id,
  event_id,
  event_schedule_line_item_id,
}) => {
  const [form] = Form.useForm();

  const { items: schedules } = useDynamicSelector("getFilteredtEventSchedules");

  const { items: sent_to_next_level_teams } = useDynamicSelector(
    "getSendToNextLevelTeams"
  );
  console.log("sent_to_next_level_teams", sent_to_next_level_teams);
  const { loading: create_sent_next_level_loading } = useDynamicSelector(
    "sendGroupParticipationToNextLevel"
  );

  const send_group_participation_to_next_level_request = useAPIRequest(
    "sendGroupParticipationToNextLevel",
    send_to_next_level_mutation
  );

  const get_filtered_event_schedules_request = useAPIRequest(
    "getFilteredtEventSchedules",
    get_filtered_schedules_query
  );

  const get_send_to_next_level_teams_request = useAPIRequest(
    "getSendToNextLevelTeams",
    get_all_teams_for_sent_to_next_level
  );

  useEffect(() => {
    get_send_to_next_level_teams_request({
      json: {
        event_schedule_line_item_id,
        event_schedule_id,
        event_id,
      },
    });
  }, []);

  useEffect(() => {
    get_filtered_event_schedules_request({
      json: { event_id, event_schedule_id },
    });
  }, [event_id, event_schedule_id]);

  const onFinish = (values) => {
    const { team_one_id, team_two_id } = values;

    // Ensure Team 1 and Team 2 are different
    if (team_one_id === team_two_id) {
      message.error("Team 1 and Team 2 cannot be the same!");
      return;
    }

    send_group_participation_to_next_level_request(
      {
        json: {
          team_one_id,
          team_two_id,
          event_schedule_line_item_id,
          event_schedule_id,
          team_one_group_participation_id: values?.schedule_id,
        },
      },
      true
    );
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      {/* Team 1 Select */}
      <Form.Item
        label="Team 1"
        name="team_one_id"
        rules={[{ required: true, message: "Please select Team 1" }]}
      >
        <Select
          placeholder="Select Team 1"
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {sent_to_next_level_teams?.map((team) => (
            <Option key={team.id} value={team.id}>
              {team.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Team 2 Select */}
      <Form.Item
        label="Team 2"
        name="team_two_id"
        rules={[{ required: true, message: "Please select Team 2" }]}
      >
        <Select
          placeholder="Select Team 2"
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {sent_to_next_level_teams?.map((team) => (
            <Option key={team.id} value={team.id}>
              {team.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Schedule Select */}
      <Form.Item
        label="Schedule Name"
        name="schedule_id"
        rules={[{ required: true, message: "Please select a Schedule Name" }]}
      >
        <Select
          placeholder="Select Schedule Name"
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {schedules?.map((record) => (
            <Option key={record.id} value={record.id}>
              {record.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        loading={create_sent_next_level_loading}
      >
        Submit
      </Button>
    </Form>
  );
};

export default SentTeamNextLevel;
