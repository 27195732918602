import { useAPIRequest } from "@helpers/hooks";
import {
  get_category_team_filter_query,
  get_districts_query,
  get_events_team_filter_query,
  get_teams_without_pagination_query,
} from "@services/graphql";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { Button, Form, Select } from "antd";
import Search from "antd/es/transfer/search";
import { startCase } from "lodash";
import React, { useEffect, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { useDownloadExcel } from "react-export-table-to-excel";
import { participant_options } from "@helpers/constants";
import { retrieve_item } from "@helpers/functions";
import toast from "react-hot-toast";
import { callLambda } from "../report/report_function";
import moment from "moment";

const { Option } = Select;

const TeamListFilters = ({ form, set_search_value, search_value }) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const [report_loading, set_report_loading] = useState(false);

  const { items: discipline_list } = useDynamicSelector(
    "get_disciplines_team_filter"
  );
  const {
    items: team_list,
    status: team_list_status,
    loading: team_list_loading,
    pagination,
  } = useDynamicSelector("get_teams_without_pagination");
  const role_name = retrieve_item("name");
  const { items: district_list } = useDynamicSelector("get_districts");
  const { items: event_schedule_list } = useDynamicSelector(
    "get_events_team_filter"
  );
  const { items: category_list } = useDynamicSelector(
    "get_category_team_filter"
  );

  const get_categories_request = useAPIRequest(
    "get_category_team_filter",
    get_category_team_filter_query
  );
  const get_events_schedule_request = useAPIRequest(
    "get_events_team_filter",
    get_events_team_filter_query
  );
  const get_teams_list_without_pagination_request = useAPIRequest(
    "get_teams_without_pagination",
    get_teams_without_pagination_query
  );
  const get_districts_request = useAPIRequest(
    "get_districts",
    get_districts_query
  );
  const dispatch = useDispatch();
  const team_filter_category_id = Form.useWatch("category", form);
  const team_filter_discipline_id = Form.useWatch("discipline", form);
  const team_filter_event_id = Form.useWatch("event", form);
  const team_filter_gender = Form.useWatch("gender", form);
  const team_filter_district = Form.useWatch("district", form);
  const is_participated = Form.useWatch("is_participated", form);
  const [team_items, set_team_items] = useState([]);

  useEffect(() => {
    get_categories_request({}, true);
  }, []);
  useEffect(() => {
    if (team_items.length > 0) {
      // team_wise_report(team_items, "team_wise", set_team_items);
      onDownload();
    }
  }, [team_items]);

  const handleDownloadReport = (type, fn, dl, excelData) => {
    var elt = document.getElementById("team-report");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "survey data" });

    var ws = wb.Sheets[wb.SheetNames[0]];

    for (var cell in ws) {
      if (ws.hasOwnProperty(cell)) {
        if (cell[0] === "!" || cell[0] === "_") continue;

        ws[cell].s = {
          alignment: { horizontal: "center" },
        };

        excelData.forEach((report) => {
          report.answers.forEach((x) => {
            if (ws[cell].v === x.unique_id) {
              ws[cell].t = "s";
              ws[cell].v = "'" + x.unique_id.toFixed(2).toString();
            }
          });
        });
      }
    }
    set_team_items([]);
    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(wb, fn || `Report.` + (type || "xlsx"));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Team_report",
    sheet: "Users",
    excelData: team_items,
    handleDownload: handleDownloadReport,
  });

  const on_values_change = useCallback((val, values) => {
    if (values?.category && values?.gender) {
      get_events_schedule_request(
        {
          discipline_id: values?.discipline,
          gender: values?.gender,
          category_id: values?.category,
        },
        true
      );
    }
  });

  const map_options = (list) =>
    list?.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const on_search = (value) => {
    // get_player_list(value);
  };

  useEffect(() => {
    if (team_list_status === "success") {
      toast.success("Report request submitted successfully");
      dispatch(dynamic_clear("get_teams_without_pagination"));
    }
  }, [team_list_status]);

  useEffect(() => {
    get_districts_request();
  }, []);

  const get_teams_list_request = async () => {
    set_report_loading(true);
    const result = await callLambda({
      request: "get_all_team_report",
      args: {
        search_string: search_value,
        category_id: team_filter_category_id,
        discipline_id: team_filter_discipline_id,
        event_id: team_filter_event_id,
        gender: team_filter_gender,
        district_id: team_filter_district,
        is_participated: is_participated,
      },
    });
    set_report_loading(false);
    console.log("result", JSON.parse(result.Payload).items);
    set_team_items(JSON.parse(result.Payload)?.items || []);
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Form
          form={form}
          layout="vertical"
          style={{ display: "flex", flexDirection: "row", gap: "10px" }}
          onValuesChange={on_values_change}
        >
          <Button
            type="primary"
            onClick={get_teams_list_request}
            loading={report_loading}
          >
            {t("export")}
          </Button>
          <Form.Item name="is_participated">
            <Select
              showSearch
              placeholder="Status"
              optionFilterProp="label"
              options={participant_options}
              style={{ width: 200 }}
              allowClear
            />
          </Form.Item>
          {role_name === "Admin" && (
            <Form.Item name="district">
              <Select
                showSearch
                placeholder="District"
                optionFilterProp="label"
                options={map_options(district_list)}
                style={{ width: 200 }}
                allowClear
              />
            </Form.Item>
          )}

          <Form.Item name="category">
            <Select placeholder="Category" allowClear>
              {category_list?.map((category) => (
                <Option value={category?.id} key={category?.id}>
                  {category?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="gender">
            <Select placeholder="Gender" allowClear>
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
            </Select>
          </Form.Item>

          <Form.Item name="discipline">
            <Select placeholder="Discipline" allowClear>
              {discipline_list?.map((discipline) => (
                <Option value={discipline?.id} key={discipline?.id}>
                  {discipline?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="event">
            <Select placeholder="Event">
              {event_schedule_list?.map((event) => (
                <Option value={event?.id} key={event?.id}>
                  {event?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="search">
            <Search
              // className="search-input-player-list"
              placeholder="Search"
              onSearch={on_search}
              value={search_value}
              onChange={(e) => set_search_value(e.target.value)}
              allowClear
              style={{
                width: 200,
              }}
            />
          </Form.Item>
        </Form>
        <div ref={tableRef} style={{ display: "none" }}>
          <table
            id="team-report"
            bordered
            className="table-style"
            style={{
              border: "1px solid black",
              borderCollapse: "collapse",
              width: "100%",
              overflow: "auto",
            }}
          >
            <thead className="table-style">
              <tr className="table-style">
                <th className="table-style">Sl.no</th>
                <th className="table-style">Team Name</th>
                <th className="table-style">Team Code</th>
                <th className="table-style">Gender</th>
                <th className="table-style">Event</th>
                <th className="table-style">District</th>
                <th className="table-style">Category</th>
              </tr>
            </thead>
            <tbody className="table-style">
              {team_items.length > 0 &&
                team_items?.map((team, index) => (
                  <React.Fragment key={team?.team_code || `team-${index}`}>
                    {index !== 0 && <tr></tr>}

                    <tr className="table-style">
                      <td
                        className="table-style"
                        style={{ fontWeight: "bold" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="table-style"
                        style={{ fontWeight: "bold" }}
                      >
                        {team?.name}
                      </td>
                      <td className="table-style">{team?.team_code}</td>
                      <td className="table-style">{startCase(team?.gender)}</td>
                      <td className="table-style">{team?.event?.name}</td>
                      <td className="table-style">{team?.district?.name}</td>
                      <td className="table-style">{team?.category?.name}</td>
                    </tr>
                    <tr className="table-style">
                      <th className="table-style"></th>
                      <th className="table-style">Sl.no</th>
                      <th className="table-style">Player Name</th>
                      <th className="table-style">Player Code</th>
                      <th className="table-style">Aadhar No</th>
                      <th className="table-style">Mobile</th>
                      <th className="table-style">DOB</th>
                    </tr>
                    {team?.players?.map((player, playerIndex) => (
                      <tr className="table-style" key={player?.id}>
                        <td className="table-style"></td>
                        <td className="table-style">{playerIndex + 1}</td>
                        <td className="table-style">{player?.name}</td>
                        <td className="table-style">{player?.player_code}</td>
                        <td className="table-style">
                          {Number(player?.aadhar_no).toLocaleString("en-US", {
                            useGrouping: false,
                          })}
                        </td>
                        <td className="table-style">{player?.mobile}</td>
                        <td className="table-style">
                          {moment(player?.dob).format("DD-MM-YYYY")}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TeamListFilters;
