import Home from "@views/pages/home";
import UserLayout from "@views/layouts/user_layout";
import OfficerLogin from "@views/pages/officer_login";
import PlayerHome from "@views/pages/player_home";
import TeamPlayers from "@views/pages/team_player";
import ForgotPassword from "@views/components/forgot_password/forgot_password";
import VerifyOtp from "@views/components/forgot_password/verify_otp";
import OfficerLayout from "@views/layouts/officer_layout";
import OfficerDashboard from "@views/pages/officer_dashboard";
import RegisteredPlayerList from "@views/pages/registered_player_list";
import Redirector from "@views/pages/redirector";
import Venue from "@views/pages/venue";
import EventSchedule from "@views/pages/event_schedule";
import ViewEventSchedule from "@views/pages/view_event_schedule";
import Team from "@views/pages/team";
import TeamPlayerList from "@views/pages/team_player_list";
import FixtureTab from "@views/components/fixture/fixture_tab";
import Report from "@views/pages/report";
import FixtureIndividualPlayers from "@views/components/fixture/fixture_individual_players";
import Register from "@views/pages/register";
import RegisterForm from "@views/pages/register_form";
import PlayerLogin from "@views/pages/player_login";
import Results from "@views/pages/results";

const my_routes = [
  {
    path: "/",
    name: "Redirector",
    component: <Redirector />,
  },
  {
    path: "/cmtrophy",
    name: "Home",
    component: <UserLayout />,
    children: [
      {
        path: "",
        name: "Home",
        component: <Home />,
        authenticate: false,
      },
      {
        path: "player-login",
        name: "Player Login",
        component: <PlayerLogin />,
        authenticate: false,
      },
      {
        path: "officer-login",
        name: "Officer Login",
        component: <OfficerLogin />,
        authenticate: false,
      },

      {
        path: "view-event-schedule",
        name: "View Event Schedule",
        component: <ViewEventSchedule />,
        authenticate: false,
      },
      {
        path: "register",
        name: "Register",
        component: <Register />,
        authenticate: true,
      },

      {
        path: "player/home",
        name: "Player Home",
        component: <PlayerHome />,
        authenticate: true,
      },
      {
        path: "player/team/:id",
        name: "Team Players",
        component: <TeamPlayers />,
        authenticate: true,
      },
      // {
      //   path: "register-form/:type?",
      //   name: "Register",
      //   component: <RegisterForm />,
      //   authenticate: true,
      // },
      {
        path: "forgot-password",
        name: "Forgot Password",
        component: <ForgotPassword />,
        authenticate: false,
      },
      {
        path: "verify-otp",
        name: "Verify Otp",
        component: <VerifyOtp />,
        authenticate: false,
      },
    ],
  },
  {
    path: "/cmtrophy/officer",
    name: "Officer",
    component: <OfficerLayout />,
    children: [
      {
        path: "dashboard",
        name: "Officer Dashboard",
        component: <OfficerDashboard />,
        authenticate: true,
      },

      {
        path: "player/:id?",
        name: "Player",
        component: <RegisteredPlayerList />,
        authenticate: true,
      },

      {
        path: "venue",
        name: "venue",
        component: <Venue />,
        authenticate: true,
      },
      {
        path: "schedules",
        name: "schedules",
        component: <EventSchedule />,
        authenticate: true,
      },
      {
        path: "teams",
        name: "Team",
        component: <Team />,
        authenticate: true,
      },
      {
        path: "team/:id",
        name: "Team Details",
        component: <TeamPlayerList />,
        authenticate: true,
      },
      {
        path: "schedules/:id?/:event_schedule_line_item_id?/:event_id?",
        name: "Team Details",
        component: <FixtureTab />,
        authenticate: true,
      },
      {
        path: "register-form/:type?",
        name: "Register",
        component: <RegisterForm />,
        authenticate: true,
      },
      // {
      //   path: "reports",
      //   name: "Reports",
      //   component: <Report />,
      //   authenticate: true,
      // },
      // {
      //   path: "results",
      //   name: "Results",
      //   component: <Results />,
      //   authenticate: true,
      // },
      {
        path: "schedules-individual/:id?/:event_schedule_line_item_id?/:event_id?/individual",
        name: "Individual Details",
        component: <FixtureIndividualPlayers />,
        authenticate: true,
      },
    ],
  },
];

export default my_routes;
