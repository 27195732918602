import React, { useEffect } from "react";
import { Form, Select, Button } from "antd";
import { useAPIRequest } from "@helpers/hooks";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import {
  get_categories_query,
  get_events_schedule_query,
} from "@services/graphql";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { retrieve_item } from "@helpers/functions";

const { Option } = Select;

const ResultsFilters = ({ form, search_value }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const name = retrieve_item("name");

  // Get data from the store
  const { items: discipline_list } = useDynamicSelector(
    "get_disciplines_schedule"
  );
  const { items: district_list } = useDynamicSelector("get_districts");
  const { items: event_schedule_list } = useDynamicSelector(
    "get_events_schedule"
  );
  const { items: category_list } = useDynamicSelector("get_categories");

  const get_categories_request = useAPIRequest(
    "get_categories",
    get_categories_query
  );
  const get_events_schedule_request = useAPIRequest(
    "get_events_schedule",
    get_events_schedule_query
  );

  const filter_category_id = Form.useWatch("category", form);
  const filter_gender = Form.useWatch("gender", form);
  const filter_discipline_id = Form.useWatch("discipline", form);

  useEffect(() => {
    get_categories_request({}, true);
  }, []);

  const on_values_change = (val, values) => {
    if (values?.category && values?.gender) {
      get_events_schedule_request(
        {
          discipline_id: values?.discipline,
          gender: values?.gender,
          category_id: values?.category,
        },
        true
      );
    }
  };

  const district_options = district_list?.map((district) => ({
    value: district.id,
    label: district.name,
  }));

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ display: "flex", flexDirection: "row", gap: "10px" }}
      onValuesChange={on_values_change}
    >
      {name === "Admin" && (
        <Form.Item name="district">
          <Select
            showSearch
            placeholder={t("District")}
            optionFilterProp="label"
            options={district_options}
            style={{ width: 250 }}
            allowClear
          />
        </Form.Item>
      )}

      <Form.Item name="category">
        <Select placeholder={t("Category")} allowClear style={{ width: 250 }}>
          {category_list?.map((category, index) => (
            <Option value={category?.id} key={index}>
              {category?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="gender">
        <Select placeholder={t("Gender")} allowClear>
          <Option value="male">{t("Male")}</Option>
          <Option value="female">{t("Female")}</Option>
        </Select>
      </Form.Item>

      <Form.Item name="discipline">
        <Select placeholder={t("Discipline")} allowClear style={{ width: 250 }}>
          {discipline_list?.map((discipline, index) => (
            <Option value={discipline?.id} key={index}>
              {discipline?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="event">
        <Select placeholder={t("Event")} style={{ width: 250 }}>
          {event_schedule_list?.map((event, index) => (
            <Option value={event?.id} key={index}>
              {event?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default ResultsFilters;
