import { useDispatch } from "react-redux";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Col,
  Row,
  Typography,
  Spin,
  Space,
  Input,
  Form,
} from "antd";
import { useAPIRequest } from "@helpers/hooks";
import { update_state_team_mutation } from "@services/graphql/event_schedule_team";
import { get_teams_query } from "@services/graphql";
import toast from "react-hot-toast";

const { Text } = Typography;

const SendToStateLevel = (props) => {
  const {
    initial_values,
    close,
    event_schedule_line_item_id,
    event_registered_players,
    event_schedule,
    action_item,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [team_name, set_team_name] = useState("");

  const { items: players } = useDynamicSelector("get_players");
  const { item: team, loading: team_loading } = useDynamicSelector("get_teams");
  const {
    loading: update_loading,
    error,
    status,
  } = useDynamicSelector("updateStateTeam");

  const update_state_team_mutation_request = useAPIRequest(
    "updateStateTeam",
    update_state_team_mutation
  );

  const get_teams_request = useAPIRequest("getTeams", get_teams_query);

  useEffect(() => {
    if (error) {
      toast.error("Something Went Wrong");
      dispatch(dynamic_clear("update_state_team_mutation"));
    } else if (status === "success") {
      toast.success("Team moved to State level successfully");
      event_registered_players();
      close();
      set_team_name("");
      dispatch(dynamic_clear("update_state_team_mutation"));
    }
  }, [error, status]);

  useEffect(() => {
    if (initial_values?.team_id) {
      get_teams_request({ json: { id: initial_values?.team_id } }, true);
    }
  }, [initial_values?.team_id]);

  const handle_submit = () => {
    const request_payload = {
      team_name,
      id: initial_values?.team_id,
      players: players.map((player) => player.id),
      event_schedule_line_item_id,
    };
    update_state_team_mutation_request(request_payload, true);
  };

  const on_team_name_change = (e) => {
    set_team_name(e.target.value);
  };

  return (
    <>
      {team_loading ? (
        <div style={{ textAlign: "center", paddingTop: "5px" }}>
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col span={8}>
              <Text strong style={{ fontSize: 16 }}>
                {`${action_item?.team.name} - ${action_item?.team?.team_code}`}
              </Text>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col span={16}>
              <Form>
                <Form.Item
                  label={t("team_name")}
                  name="team_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the team name!",
                    },
                  ]}
                >
                  <Input value={team_name} onChange={on_team_name_change} />
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <Row gutter={16}>
            {players?.map((x) => (
              <Col span={8} key={x.id} style={{ marginBottom: "16px" }}>
                <Card>
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <Text>{x.name}</Text>
                    <Text>{x.player_code}</Text>
                  </Space>
                </Card>
              </Col>
            ))}
          </Row>

          <Space
            style={{
              marginTop: "20px",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              loading={update_loading}
              onClick={handle_submit}
              type="primary"
            >
              {t("Submit")}
            </Button>
            <Button onClick={close} type="danger">
              {t("Close")}
            </Button>
          </Space>
        </div>
      )}
    </>
  );
};

export default SendToStateLevel;
