import { useDispatch } from "react-redux";
import { useDynamicSelector } from "@services/redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Space, Select, Typography } from "antd";
import { useAPIRequest } from "@helpers/hooks";
import {
  get_all_positions_query,
  get_event_parameters_query,
  get_individual_participation_query,
  update_performance_mutation,
} from "@services/graphql/performance";
import { get_events_schedule_query } from "@services/graphql";

const PerformanceDetails = (props) => {
  const { initialValues, close, event_schedule_line_item_id, rerender } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { event_id } = useParams();
  const [parameterList, setParameterList] = useState([]);

  console.log("event_id", event_id);

  const { loading: createLoading } = useDynamicSelector("createEventVenue");
  const { loading: updateLoading } = useDynamicSelector(
    "updateIndividualParticipation"
  );
  const performance = useDynamicSelector("getIndividualParticipation");
  const { items: positions } = useDynamicSelector("getPositions");
  const { items: parameters } = useDynamicSelector("getEventParameters");

  const get_all_positions_request = useAPIRequest(
    "getPositions",
    get_all_positions_query
  );
  const get_individual_participation_request = useAPIRequest(
    "getIndividualParticipation",
    get_individual_participation_query
  );
  const get_event_parameters_request = useAPIRequest(
    "getEventParameters",
    get_event_parameters_query
  );
  const update_performance_participation_request = useAPIRequest(
    "updateIndividualParticipation",
    update_performance_mutation
  );

  useEffect(() => {
    get_all_positions_request({}, true);
  }, []);

  useEffect(() => {
    if (performance?.individual_participation_parameters?.length) {
      setParameterList(performance.individual_participation_parameters);
    } else if (parameters?.length) {
      setParameterList(parameters);
    }
  }, [parameters, performance]);

  useEffect(() => {
    if (initialValues) {
      get_individual_participation_request(
        {
          json: {
            id: initialValues?.id,
          },
        },
        true
      );
    }
  }, [initialValues, rerender]);

  useEffect(() => {
    if (event_id) {
      get_event_parameters_request({
        json: {
          event_id: event_id,
        },
      });
    }
  }, [event_id, initialValues]);

  const prefillForm = () => {
    if (performance) {
      const individualParticipationParameters =
        performance?.individual_participation_parameters?.map((param) => ({
          event_parameter_id: param.event_parameter_id,
          value: param.value,
          id: param.id,
        })) || [];

      form.setFieldsValue({
        individual_participation_parameters: individualParticipationParameters,
        position_id: performance.position_id,
      });
    }
  };

  useEffect(() => {
    prefillForm();
  }, [performance]);
  const handleSubmit = (values) => {
    // Ensure the correct structure for individual_participation_parameters
    const formattedParameters = values.individual_participation_parameters.map(
      (param, index) => ({
        value: param.value,
        event_parameter_id:
          param.event_parameter_id || parameterList[index]?.id, // Get the event_parameter_id from the parameterList or form data
        id: parameterList[index]?.id || null, // Include 'id' if available, otherwise null
      })
    );

    // Attach the event_schedule_line_item_id to values
    values.event_schedule_line_item_id = event_schedule_line_item_id;

    // Prepare the final object for submission
    const submissionData = {
      json: {
        id: initialValues?.id,
        data: {
          ...values,
          individual_participation_parameters: formattedParameters, // Use the formatted parameters
        },
      },
    };

    // Call the API to update the performance participation
    update_performance_participation_request(submissionData);
  };

  return (
    <div
      style={{ overflowX: "hidden", display: "flex", justifyContent: "center" }}
    >
      <div style={{ maxWidth: "600px", width: "100%" }}>
        <div style={{ marginBottom: "10px" }}>
          <Typography.Title level={4}>
            {performance?.player?.name} - {performance?.player?.player_code}
          </Typography.Title>
        </div>
        <Form form={form} onFinish={handleSubmit}>
          {parameterList?.map((parameter, index) => (
            <div key={parameter.id || index} style={{ marginBottom: "16px" }}>
              <Form.Item
                name={["individual_participation_parameters", index, "value"]}
                label={parameter.event_parameter?.name || parameter.name}
                rules={[
                  {
                    required: true,
                    message: `${
                      parameter.event_parameter?.name || parameter.name
                    } is required`,
                  },
                ]}
              >
                {parameter.event_parameter?.type === "text" ||
                parameter.type === "text" ? (
                  <Input />
                ) : parameter.event_parameter?.type === "int" ||
                  parameter.type === "int" ? (
                  <Input type="number" />
                ) : parameter.event_parameter?.type === "decimal" ||
                  parameter.type === "decimal" ? (
                  <Input type="number" step="0.01" />
                ) : parameter.event_parameter?.type === "time" ||
                  parameter.type === "time" ? (
                  <Input />
                ) : null}
              </Form.Item>
              <Form.Item
                name={[
                  "individual_participation_parameters",
                  index,
                  "event_parameter_id",
                ]}
                initialValue={parameter.event_parameter_id}
                hidden
              >
                <Input />
              </Form.Item>
            </div>
          ))}

          <div style={{ marginBottom: "16px" }}>
            <Form.Item
              name="position_id"
              label={t("Position")}
              rules={[
                {
                  required: true,
                  message: "Position is required",
                },
              ]}
            >
              <Select
                placeholder="Select a position"
                options={positions}
                fieldNames={{
                  label: "name",
                  value: "id",
                }}
              />
            </Form.Item>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Space size="large" style={{ marginTop: "20px" }}>
              <Button onClick={close}>{t("Cancel")}</Button>
              <Button
                htmlType="submit"
                loading={createLoading || updateLoading}
                type="primary"
              >
                {t("Save")}
              </Button>
            </Space>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PerformanceDetails;
