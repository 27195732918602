import React, { useEffect, useCallback } from "react";
import { Button } from "antd";
import * as XLSX from "xlsx";
import { get_districtwise_categorywise_report_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { useAPIRequest } from "@helpers/hooks";

const RegionWiseCategoryWiseReport = () => {
  const { items: event_list } = useDynamicSelector(
    "get_districtwise_categorywise_report"
  );
  const getReport = useAPIRequest(
    "get_districtwise_categorywise_report",
    get_districtwise_categorywise_report_query
  );

  useEffect(() => {
    getReport();
  }, []);

  const formatData = useCallback((data) => {
    return data.map(({ error, ...rest }) => {
      return Object.keys(rest).reduce((acc, key) => {
        const formattedKey = key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase());
        acc[formattedKey] = rest[key];
        return acc;
      }, {});
    });
  }, []);

  const downloadExcel = useCallback(() => {
    if (event_list && event_list.length > 0) {
      const formattedData = formatData(event_list);
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Region Report");
      XLSX.writeFile(workbook, "RegionWiseCategoryWiseReport.xlsx");
    }
  }, [event_list, formatData]);

  return (
    <Button
      onClick={downloadExcel}
      type="primary"
      style={{ marginRight: "6px" }}
    >
      District wise Report
    </Button>
  );
};

export default RegionWiseCategoryWiseReport;
