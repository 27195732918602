import {
  getAllPerformance,
  getAllPerformanceSelector,
  useDynamicSelector,
  dynamicClear,
  dynamic_clear,
} from "@services/redux";
import { Button, Card, Typography, Row, Col, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useAPIRequest } from "@helpers/hooks";
import {
  delete_individual_participation_to_next_level_mutation,
  get_individual_fixture_query,
} from "@services/graphql/performance";
import TextArea from "antd/es/input/TextArea";
import { useParams } from "react-router-dom";

const RemoveFromThisLevel = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { event_schedule_line_item_id } = useParams();

  const [participantList, setParticipantList] = useState([]);
  const { initialValues, close, player_id, rerender } = props;

  console.log("iiii", event_schedule_line_item_id);

  const {
    loading: delete_loading,
    error: delete_error,
    status: delete_status,
  } = useDynamicSelector("deleteIndividualParticipationToNextLevel");

  const { items: participants } = useDynamicSelector(
    "getIndividualParticipations"
  );

  const get_individual_fixture_request = useAPIRequest(
    "getIndividualParticipations",
    get_individual_fixture_query
  );

  const delete_individual_participation_to_next_level_request = useAPIRequest(
    "deleteIndividualParticipationToNextLevel",
    delete_individual_participation_to_next_level_mutation
  );

  useEffect(() => {
    get_individual_fixture_request({
      json: {
        event_schedule_line_item_id: event_schedule_line_item_id,
        page_number: 1,
        page_limit: 10,
        search_string: "",
      },
    });
  }, [event_schedule_line_item_id]);

  useEffect(() => {
    let filteredArray = participants?.filter((p) => player_id?.includes(p.id));
    setParticipantList(filteredArray);
  }, [player_id, participants]);

  useEffect(() => {
    if (delete_error) {
      close();
      toast.error("Something went wrong!");
      dispatch(dynamic_clear("deleteIndividualParticipationToNextLevel"));
    } else if (delete_status === "success") {
      close();
      toast.success("Removed Successfully");
      dispatch(dynamic_clear("deleteIndividualParticipationToNextLevel"));
      get_individual_fixture_request({
        json: {
          event_schedule_line_item_id: event_schedule_line_item_id,
          page_number: 1,
          page_limit: 10,
          search_string: "",
        },
      });
    }
  }, [delete_status, delete_error]);

  console.log("event_schedule_line_item_id", event_schedule_line_item_id);

  const handleSubmit = () => {
    delete_individual_participation_to_next_level_request({
      player_id: initialValues?.player_id,
      individual_participation_id: initialValues?.id,
      event_schedule_line_item_id: event_schedule_line_item_id,
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [rerender]);

  return (
    <>
      <div style={{ padding: "20px" }}>
        <Typography.Text>
          {`Are you sure you want to remove ${
            initialValues ? initialValues?.player?.name : "these Players"
          } from this level?`}
        </Typography.Text>

        <Card style={{ marginTop: "20px", backgroundColor: "#53c68c" }}>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col>
              <Typography.Text strong>
                {initialValues?.player?.name}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text>
                {initialValues?.player?.player_code}
              </Typography.Text>
            </Col>
          </Row>
        </Card>
      </div>

      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        id={props.id}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="remarks"
              label={
                <>
                  <Typography.Text
                    style={{ fontFamily: "Marutham", fontSize: 16 }}
                  >
                    கருத்துக்கள்
                  </Typography.Text>
                  <Typography.Text
                    style={{ fontFamily: "Roboto", fontSize: 16 }}
                  >
                    &nbsp;/ Remarks
                  </Typography.Text>
                </>
              }
            >
              <TextArea rows={4} placeholder="Enter remarks" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" gutter={10}>
          <Col>
            <Button
              type="primary"
              loading={delete_loading}
              htmlType="submit"
              style={{ backgroundColor: "#161680" }}
            >
              {t("Submit")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RemoveFromThisLevel;
