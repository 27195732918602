import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Select, Input, Button, Row, Col, Typography, Space, Form } from "antd";
import { useDynamicSelector } from "@services/redux";
import { useParams } from "react-router-dom";
import { useAPIRequest } from "@helpers/hooks";
import {
  create_fixture_mutation,
  get_one_fixture_query,
  update_fixture_mutation,
} from "@services/graphql/event_schedule_team";

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const TeamFixtureDetails = ({
  initialValues,
  close,
  action_type,
  form,
  action_item,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [teamOneId, setTeamOneId] = useState(null);
  const [teamTwoId, setTeamTwoId] = useState(null);
  const [showError, setShowError] = useState(false);

  const { event_schedule_line_item_id } = useParams();

  const { loading: createLoading } = useDynamicSelector(
    "createGroupParticipation"
  );
  const { loading: updateLoading } = useDynamicSelector(
    "updateGroupParticipation"
  );
  const { items: teams } = useDynamicSelector("getEventScheduleAddedTeams");
  const fixture = useDynamicSelector("getGroupParticipation");

  const get_group_participation_request = useAPIRequest(
    "getGroupParticipation",
    get_one_fixture_query
  );
  const create_group_participation_request = useAPIRequest(
    "createGroupParticipation",
    create_fixture_mutation
  );

  const update_fixture_request = useAPIRequest(
    "updateGroupParticipation",
    update_fixture_mutation
  );
  // Handle form submission
  const handleSubmit = (values) => {
    if (teamOneId === teamTwoId) {
      setShowError(true); // Show error if same teams are selected
      return;
    }
    setShowError(false);

    const data = {
      team_one_id: teamOneId,
      team_two_id: teamTwoId,
      referee: values.referee,
      remarks: values.remarks,
      event_schedule_line_item_id: event_schedule_line_item_id,
    };

    {
      action_type === "add"
        ? create_group_participation_request(
            {
              json: {
                data,
              },
            },
            true
          )
        : update_fixture_request(
            {
              json: {
                data,
              },
            },
            true
          );
    }
  };

  console.log("ideeeee", action_item?.id);

  useEffect(() => {
    get_group_participation_request({
      json: {
        id: action_item?.id,
      },
    });
  }, [action_item?.id]);

  useEffect(() => {
    if (fixture && action_type === "edit") {
      setTeamOneId(fixture.team_one_id);
      setTeamTwoId(fixture.team_two_id);
      form.setFieldsValue({
        teamOneId: fixture.team_one?.name,
        teamTwoId: fixture.team_two?.name,
        referee: fixture.referee,
        remarks: fixture.remarks,
      });
    }
  }, [fixture, action_type]);

  return (
    <Form layout="vertical" onFinish={handleSubmit} form={form}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Select Team 1"
            name="teamOneId"
            rules={[{ required: true, message: "Please select Team 1" }]}
          >
            <Select
              placeholder="Select Team 1"
              value={teamOneId}
              onChange={(value) => setTeamOneId(value)}
            >
              {teams?.map((team) => (
                <Option key={team.team.id} value={team.team.id}>
                  {team.team.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Select Team 2"
            name="teamTwoId"
            rules={[{ required: true, message: "Please select Team 2" }]}
          >
            <Select
              placeholder="Select Team 2"
              value={teamTwoId}
              onChange={(value) => setTeamTwoId(value)}
            >
              {teams?.map((team) => (
                <Option key={team.team.id} value={team.team.id}>
                  {team.team.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {showError && (
        <Row>
          <Col span={24}>
            <Text type="danger">Teams must be different!</Text>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Referee"
            name="referee"
            rules={[{ required: true, message: "Please enter the referee" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Remarks"
            name="remarks"
            rules={[{ required: true, message: "Please enter remarks" }]}
          >
            <TextArea placeholder="Remarks" rows={4} />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end">
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={createLoading || updateLoading}
          >
            {t("Submit")}
          </Button>
          <Button type="danger" onClick={close}>
            {t("Close")}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default TeamFixtureDetails;
