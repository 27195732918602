import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "@services/redux";
import { Button, Col, Form, Input, Row, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useAPIRequest } from "@helpers/hooks";
import {
  get_event_parameters_query,
  get_filtered_schedules_query,
} from "@services/graphql/performance";
import {
  get_one_fixture_query,
  update_fixture_mutation,
} from "@services/graphql/event_schedule_team";

const { TextArea } = Input;

const TeamsRecordPerformance = (props) => {
  const {
    initial_values,
    discipline_id,
    event_schedule_line_item_id,
    event_id,
    rerender,
    event_schedule_id,
    set_parameter_list,
    parameter_list,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [action_item, set_action_item] = useState({});
  const [winner_teams, set_winner_teams] = useState([]);

  const { loading: update_loading } = useDynamicSelector(
    "updateGroupParticipation"
  );

  const { items: teams } = useDynamicSelector("getFilteredtEventSchedules");
  const fixture = useDynamicSelector("getGroupParticipation");
  const { items: parameters } = useDynamicSelector("getEventParameters");

  const get_filtered_event_schedules = useAPIRequest(
    "getFilteredtEventSchedules",
    get_filtered_schedules_query
  );
  const get_group_participation_request = useAPIRequest(
    "getGroupParticipation",
    get_one_fixture_query
  );
  const get_event_parameters_request = useAPIRequest(
    "getEventParameters",
    get_event_parameters_query
  );
  const update_fixture_request = useAPIRequest(
    "updateGroupParticipation",
    update_fixture_mutation
  );

  useEffect(() => {
    get_filtered_event_schedules({ json: { event_id, event_schedule_id } });
  }, [discipline_id, event_id, event_schedule_id]);

  useEffect(() => {
    if (parameters) {
      set_parameter_list(parameters);
    }
  }, [parameters, fixture, rerender]);

  useEffect(() => {
    if (fixture) {
      set_winner_teams([fixture.team_one, fixture.team_two]);
      set_action_item({
        group_participation_parameters:
          fixture.group_participation_parameters || [],
        remarks: fixture.remarks,
        team_one: fixture.team_one?.name,
        team_two: fixture.team_two?.name,
        team_one_id: fixture.team_one_id,
        team_two_id: fixture.team_two_id,
        winner_id: fixture.winner_id || null,
      });
    }
  }, [fixture]);

  useEffect(() => {
    if (event_id) {
      get_event_parameters_request({ json: { event_id } });
    }
  }, [event_id, rerender]);

  useEffect(() => {
    if (initial_values) {
      get_group_participation_request({ json: { id: initial_values.id } });
    }
  }, [initial_values, rerender]);

  useEffect(() => {
    form.setFieldsValue(action_item);
  }, [action_item, form]);

  const handle_submit = (values) => {
    const updated_group_participation_parameters = parameter_list.flatMap(
      (param) =>
        winner_teams.map((team) => {
          const existingParameter =
            initial_values?.group_participation_parameters.find(
              (gp) =>
                gp.team_id === team.id && gp.event_parameter_id === param.id
            );

          const updatedValue = values?.group_participation_parameters.find(
            (x) => x.team_id === team.id && x.event_parameter_id === param.id
          )?.value;

          return {
            id: existingParameter?.id || param.id,
            team_id: team.id,
            value: updatedValue !== undefined ? updatedValue : "",
            event_parameter_id: param.id,
          };
        })
    );

    const updated_values = {
      id: initial_values?.id,
      data: {
        team_one_id: values.team_one_id,
        team_two_id: values.team_two_id,
        winner_id: values.winner_id,
        remarks: values.remarks,
        group_participation_parameters: updated_group_participation_parameters,
      },
    };

    update_fixture_request({ json: updated_values });
  };

  return (
    <div style={{ padding: "20px", width: "100%" }}>
      <Form
        form={form}
        initialValues={action_item}
        onFinish={handle_submit}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={t("Team 1")}
              name="team_one"
              rules={[{ required: true, message: t("Team 1 is required") }]}
            >
              <Select
                options={teams}
                fieldNames={{ label: "name", value: "id" }}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("Team 2")}
              name="team_two"
              rules={[{ required: true, message: t("Team 2 is required") }]}
            >
              <Select
                options={teams}
                fieldNames={{ label: "name", value: "id" }}
                disabled
              />
            </Form.Item>
          </Col>
          {parameter_list?.map((param, index) =>
            winner_teams.map((team, team_index) => (
              <React.Fragment key={`${param.id}-${team.id}`}>
                <Form.Item
                  name={[
                    "group_participation_parameters",
                    index * winner_teams.length + team_index,
                    "event_parameter_id",
                  ]}
                  initialValue={param.event_parameter_id}
                  hidden
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[
                    "group_participation_parameters",
                    index * winner_teams.length + team_index,
                    "team_id",
                  ]}
                  initialValue={team?.id}
                  hidden
                >
                  <Input />
                </Form.Item>
                {(param.type === "int" ||
                  param.event_parameter?.type === "int") && (
                  <Col span={12}>
                    <Form.Item
                      label={`${team?.name} ${
                        param.name || param.event_parameter?.name
                      }`}
                      name={[
                        "group_participation_parameters",
                        index * winner_teams.length + team_index,
                        "value",
                      ]}
                      rules={[
                        {
                          required: true,
                          message: `${team?.name} ${
                            param.name || param.event_parameter?.name
                          } is required`,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                )}
              </React.Fragment>
            ))
          )}
          <Col span={12}>
            <Form.Item
              label={t("Winning Team")}
              name="winner_id"
              rules={[
                { required: true, message: t("Winning team is required") },
              ]}
            >
              <Select
                options={winner_teams?.map((team) => ({
                  label: team?.name,
                  value: team?.id,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label={t("Remarks")} name="remarks">
              <TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={update_loading}
            style={{ backgroundColor: "#161680", borderColor: "#161680" }}
          >
            {t("Submit")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TeamsRecordPerformance;
