import React from "react";
import { Card, Avatar, Typography, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";

const { Title } = Typography;

const ResultsRankCard = () => {
  const name = "mugesh";
  const avatarSrc = "https://example.com/avatar.jpg";
  return (
    <Card
      style={{
        width: 200,
        height: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      cover={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Avatar src={avatarSrc} size={64} />
          <Title level={4} style={{ margin: 0 }}>
            {name}
          </Title>
        </div>
      }
      actions={[<Button icon={<EditOutlined />} />]}
    >
      {/* Card content can go here if needed */}
    </Card>
  );
};

export default ResultsRankCard;
