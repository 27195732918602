import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Table, Pagination, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useDynamicSelector } from "@services/redux";
import { useTranslation } from "react-i18next";
import { FcPositiveDynamic } from "react-icons/fc";

import ParticipantsDetails from "./add_team_details";
import { useAPIRequest } from "@helpers/hooks";
import { get_event_registered_players_query } from "@services/graphql/event_register";
import {
  get_disciplines_query,
  get_one_event_query,
  get_players_query,
} from "@services/graphql";
import { get_participated_team_query } from "@services/graphql/event_schedule_team";
import { retrieve_item } from "@helpers/functions";
import RemoveFromThisLevel from "./remove_from_this_schedule";
import SendToStateLevel from "../sent_to_state_level/sent_to_state_level";

const AddedTeamListForFixture = (props) => {
  const dispatch = useDispatch();
  const {
    event_id,
    event_schedule_line_item_id,
    discipline_id,
    id,
    schedule_name,
    event_schedule,
    action_type,
  } = props;

  const navigate = useNavigate();
  const roles = retrieve_item("name");

  const { loading, items, pagination } = useDynamicSelector(
    "getEventScheduleAddedTeams"
  );
  const { team_list } = useDynamicSelector("get_registered_players");

  const { item: event } = useDynamicSelector("get_event");

  const { t } = useTranslation();
  const [current_page, set_current_page] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [next_level_rerender, set_next_level_rerender] = useState(false);
  const [is_modal_open, set_modal_open] = useState(false);
  const [action_item, set_action_item] = useState({});
  const [is_participants_modal_open, set_participants_modal_open] =
    useState(false);
  const [is_state_level_modal_open, set_state_level_modal_open] =
    useState(false);
  const [is_remove_bulk_modal_open, set_remove_bulk_modal_open] =
    useState(false);
  const [already_selected_rows, set_already_selected_rows] = useState([]);
  const [rerender, set_rerender] = useState(false);
  const [is_send_to_next_level_modal_open, set_send_to_next_level_modal_open] =
    useState(false);

  const event_registered_players_request = useAPIRequest(
    "getEventRegisteredPlayers",
    get_event_registered_players_query
  );
  const get_disciplines_request = useAPIRequest(
    "get_disciplines",
    get_disciplines_query
  );
  const get_event_request = useAPIRequest("get_event", get_one_event_query);
  const get_participated_team_request = useAPIRequest(
    "getEventScheduleAddedTeams",
    get_participated_team_query
  );
  const get_player_query_request = useAPIRequest(
    "get_players",
    get_players_query
  );

  useEffect(() => {
    event_registered_players_request(
      {
        event_id: event_id,
        gender: event_schedule?.[0]?.gender,
        category_id: event_schedule?.[0]?.category?.id,
        type: event_schedule?.[0]?.event_schedule_line_items?.[0]?.event.type,
        gender: event_schedule?.[0]?.gender,
        differently_abled_id: event_schedule?.[0]?.differently_abled_id || "",
      },
      true
    );
    get_disciplines_request({}, true);
  }, [dispatch, event_id, event_schedule]);

  useEffect(() => {
    if (team_list?.length) {
      set_already_selected_rows(team_list);
    }
  }, [team_list]);

  useEffect(() => {
    if (event_id) {
      get_event_request({ id: event_id }, true);
    }
  }, [dispatch, event_id]);

  useEffect(() => {
    if (event_schedule_line_item_id) {
      get_participated_team_request(
        {
          json: {
            event_schedule_line_item_id: event_schedule_line_item_id,
            page_number: current_page,
            page_limit: page_limit,
            search_string: "",
          },
        },
        true
      );
    }
  }, [event_schedule_line_item_id, current_page, page_limit]);

  const handle_add_participants = () => {
    set_rerender(!rerender);
    set_participants_modal_open(true);
  };

  const handle_remove_bulk_modal_open = () => {
    set_remove_bulk_modal_open(true);
  };

  const handle_remove_bulk_modal_close = () => {
    set_remove_bulk_modal_open(false);
  };

  const handle_next_level_modal_open = (record) => {
    set_send_to_next_level_modal_open(true);
    set_action_item({
      team_id: record.team_id,
      team: record.team,
    });
  };

  const handle_state_level_modal_open = (record) => {
    set_state_level_modal_open(true);
    set_next_level_rerender(!next_level_rerender);
    set_action_item({
      team_id: record.team_id,
      team: record.team,
    });

    get_player_query_request(
      {
        event_id: event_id,
        team_id: record.team_id,
        category_id: event_schedule?.[0]?.category?.id,
        gender: event_schedule?.[0]?.gender,
        page_number: 1,
        page_limit: 50,
      },
      true
    );
  };

  const handle_state_level_modal_close = () => {
    set_state_level_modal_open(false);
    set_send_to_next_level_modal_open(false);
  };

  const columns = [
    {
      title: t("Team Name"),
      dataIndex: "team",
      key: "team_name",
      render: (record) => record.name,
    },
    {
      title: t("Team Code"),
      dataIndex: "team",
      key: "team_code",
      render: (record) => record.team_code,
    },
    {
      title: t("Actions"),
      dataIndex: "",
      key: "actions",
      render: (record) => (
        <div style={{ gap: "20px", display: "flex" }}>
          <Button
            icon={<FcPositiveDynamic />}
            // onClick={() => handle_state_level_modal_open(record)}
            // disabled={roles?.[0]?.name === "District" && already_selected_rows.length === 1 ||
            //            roles?.[0]?.name === "Zone" && already_selected_rows.length === 3}
          />
          {/* <Button
            icon={<MdOutlineMoveUp color="#0d6659" />}
            onClick={() => handle_next_level_modal_open(record)}
            disabled={roles?.[0]?.name === "District" && already_selected_rows.length === 1 ||
                      roles?.[0]?.name === "Zone" && already_selected_rows.length === 3}
          /> */}
        </div>
      ),
    },
  ];

  const fetch_data = (page, page_size) => {
    get_participated_team_request(
      {
        json: {
          event_schedule_line_item_id: event_schedule_line_item_id,
          page_number: page,
          page_limit: page_size,
        },
      },
      true
    );
    set_current_page(page);
    set_page_limit(page_size);
  };

  return (
    <>
      <div style={{ padding: "20px", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            marginBottom: "10px",
          }}
        >
          <Button type="primary" onClick={handle_add_participants}>
            {t("Add Team")}
          </Button>
          {roles !== "Admin" && (
            <Button
              danger
              onClick={handle_remove_bulk_modal_open}
              disabled={!already_selected_rows.length}
            >
              {t("Remove From this Level")}
            </Button>
          )}
          <Button type="default" onClick={() => navigate(-1)} danger>
            {t("Back")}
          </Button>
        </div>

        <Table
          rowKey="team_id"
          loading={loading}
          dataSource={items}
          columns={columns}
          pagination={false}
          rowSelection={{
            selectedRowKeys: already_selected_rows,
            onChange: (selectedRowKeys) => {
              set_already_selected_rows(selectedRowKeys);
            },
            getCheckboxProps: (record) => ({
              disabled: record.is_send_to_next_level,
            }),
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}
        >
          <Pagination
            total={pagination?.total_count}
            showSizeChanger
            current={current_page}
            onChange={fetch_data}
          />
        </div>
      </div>

      <Modal
        open={is_participants_modal_open}
        onCancel={() => set_participants_modal_open(false)}
        title={t("Add Team")}
        footer={null}
        width={1000}
      >
        <ParticipantsDetails
          closeModal={() => set_participants_modal_open(false)}
          discipline_id={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          event_id={event_id}
          schedule={props?.location?.state}
          player_id={already_selected_rows}
          participantList={items}
          event_schedule_id={id}
          action_type={action_type}
          schedule_name={schedule_name}
          rerender={rerender}
          event_schedule={event_schedule}
        />
      </Modal>

      <Modal
        open={is_remove_bulk_modal_open}
        onCancel={handle_remove_bulk_modal_close}
        title={t("Remove From this Level")}
        footer={null}
        width={800}
      >
        <RemoveFromThisLevel
          closeModal={handle_remove_bulk_modal_close}
          discipline_id={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          event_id={event_id}
          team_id={already_selected_rows}
          schedule={props?.location?.state}
          schedule_name={schedule_name}
        />
      </Modal>

      <Modal
        open={is_state_level_modal_open}
        onCancel={handle_state_level_modal_close}
        title={t("Send to State Level")}
        footer={null}
        width={1000}
      >
        <SendToStateLevel
          closeModal={handle_state_level_modal_close}
          discipline_id={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          event_id={event_id}
          actionItem={action_item}
        />
      </Modal>

      {/* <Modal
        open={is_send_to_next_level_modal_open}
        onCancel={handle_state_level_modal_close}
        title={t("Send to Next Level")}
        footer={null}
        width={500}
      >
        <SentToNextLevel
          handleStateLevelModalClose={handle_state_level_modal_close}
        />
      </Modal> */}
    </>
  );
};

export default AddedTeamListForFixture;
