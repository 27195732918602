import { useDynamicSelector } from "@services/redux";
import { Row, Col, Typography, Card, Tabs } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useAPIRequest } from "@helpers/hooks";
import { get_one_event_query } from "@services/graphql";
import AddedTeamListForFixture from "./add_team_for_fixture/added_team_list";
import moment from "moment";
import FixtureTeamNextLevel from "./add_team_for_fixture/fixture_team_next_level";

const { TabPane } = Tabs;
const { Text } = Typography;

const FixtureTab = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { id, event_id, event_schedule_line_item_id, event_schedule_id } =
    useParams();
  const get_one_event_request = useAPIRequest("get_event", get_one_event_query);

  console.log(
    "hhh",
    id,
    event_schedule_id,
    event_schedule_line_item_id,
    event_id
  );

  const discipline_id =
    props?.location?.state?.[0]?.event_schedule_line_items?.[0]?.event
      ?.discipline_id;

  useEffect(() => {
    if (event_id) {
      get_one_event_request({ id: event_id }, true);
    }
  }, [event_id]);

  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  const LabelValuePair = ({ label, value }) => (
    <Row style={{ marginBottom: "8px" }}>
      <Col span={6}>
        <Text strong>{label}</Text>
      </Col>
      <Col span={1}>:</Col>
      <Col span={17}>
        <Text>{value}</Text>
      </Col>
    </Row>
  );

  return (
    <Card style={{ margin: "10px", marginTop: "10px" }}>
      <div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <LabelValuePair
              label={t("Schedule")}
              value={location?.state?.map((x) => x?.name)?.join(", ")}
            />
          </Col>
          <Col span={12}>
            <LabelValuePair
              label={t("Venue")}
              value={location?.state
                ?.map((x) => x?.event_venue?.name)
                .join(", ")}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <LabelValuePair
              label={t("Discipline")}
              value={location?.state
                ?.map(
                  (x) =>
                    x?.event_schedule_line_items?.[0]?.event?.discipline?.name
                )
                ?.join(", ")}
            />
          </Col>
          <Col span={12}>
            <LabelValuePair
              label={t("Event")}
              value={location?.state
                ?.map((x) => x?.event_schedule_line_items?.[0]?.event?.name)
                ?.join(", ")}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <LabelValuePair
              label={t("Round")}
              value={location?.state?.map((x) => x?.round?.name).join(", ")}
            />
          </Col>
          <Col span={12}>
            <LabelValuePair
              label={t("Level")}
              value={location?.state?.map((x) => x?.level?.name)?.join(", ")}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <LabelValuePair
              label={t("Date")}
              value={moment(Number(location?.state[0].date_time)).format(
                "DD/MM/YYYY"
              )}
            />
          </Col>
        </Row>
      </div>

      <Tabs defaultActiveKey="1" centered>
        <TabPane tab={t("Team")} key="1">
          <AddedTeamListForFixture
            id={id}
            event_id={event_id}
            event_schedule_line_item_id={event_schedule_line_item_id}
            event_schedule_id={event_schedule_id}
            discipline_id={discipline_id}
            event_schedule={location?.state}
            round={
              location?.state?.map((x) => {
                return Number(x.round?.name) + 1;
              })[0]
            }
            schedule_name={location?.state?.map((x) => {
              return x?.name;
            })}
          />
        </TabPane>
        <TabPane tab={t("Fixtures")} key="2">
          <FixtureTeamNextLevel
            event_schedule_line_item_id={event_schedule_line_item_id}
            event_id={event_id}
            event_schedule_id={id}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default FixtureTab;
