import { useAPIRequest } from "@helpers/hooks";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Table,
  Modal,
  Pagination,
  Row,
  Space,
  Typography,
  Tooltip,
  Col,
  Form,
} from "antd";
import { AiOutlineEdit, AiOutlineSetting } from "react-icons/ai";
import {
  get_fixture_list_query,
  get_fixtures_query,
} from "@services/graphql/event_schedule_team";
import { get_one_event_query } from "@services/graphql";
import TeamFixtureDetails from "../team_fixture_details/team_fixture_detail";
import SentTeamNextLevel from "../team_fixture_details/sent_team_next_level";
import toast from "react-hot-toast";
import TeamsRecordPerformance from "../team_fixture_details/teams_record_performance";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const FixtureTeamNextLevel = (props) => {
  const {
    event_schedule_id,
    event_id,
    event_schedule_line_item_id,
    discipline_id,
  } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [action_item, set_action_item] = useState({});
  const [action_item_performance, set_action_item_performance] = useState({});
  const [rerender, set_rerender] = useState(false);
  const [next_level, set_next_level] = useState(false);
  const [state_level, set_state_level] = useState(false);
  const [action_type, set_action_type] = useState("add");
  const [current_page, set_current_page] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [is_modal_open, set_modal_open] = useState(false);
  const navigate = useNavigate();
  const [is_performance_modal_open, set_performance_modal_open] =
    useState(false);

  const { t } = useTranslation();
  const [parameter_list, set_parameter_list] = useState([]);

  const {
    items: fixture_team_list,
    total_items,
    loading,
    pagination,
  } = useDynamicSelector("getGroupParticipationsList");

  const { loading: event_schedule_team_loading } = useDynamicSelector(
    "getEventScheduleAddedTeams"
  );
  console.log("event_schedule_team_loading", event_schedule_team_loading);

  const {
    loading: team_sent_next_level_loading,
    error: team_sent_next_level_error,
    status: team_sent_next_level_status,
  } = useDynamicSelector("sendGroupParticipationToNextLevel");

  console.log("team_sent_next_level_status", team_sent_next_level_status);

  const { item: event } = useDynamicSelector("get_event");
  const { error: create_error, status: create_success } = useDynamicSelector(
    "createGroupParticipation"
  );
  const { error: update_error, status: update_success } = useDynamicSelector(
    "updateGroupParticipation"
  );

  const get_event_request = useAPIRequest("get_event", get_one_event_query);
  console.log("fixture_team_list", fixture_team_list);

  const get_group_participations_list_request = useAPIRequest(
    "getGroupParticipationsList",
    get_fixture_list_query
  );

  const get_group_participations_list = () => {
    get_group_participations_list_request(
      {
        json: {
          event_schedule_line_item_id: event_schedule_line_item_id,
          page_limit: page_limit,
          page_number: current_page,
        },
      },
      true
    );
  };

  useEffect(() => {
    if (event_schedule_line_item_id) {
      get_group_participations_list();
    }
  }, [event_schedule_line_item_id, current_page, page_limit]);

  console.log("create_error", create_error?.message);

  useEffect(() => {
    if (create_success === "success" || update_success === "success") {
      toast.success(
        create_success === "success"
          ? "Add Team Fixtures Success"
          : "Update Team Fixtures Success"
      );
      form.resetFields();
      set_modal_open(false);
      get_group_participations_list();
      set_action_type("");
      set_action_item("");
      set_parameter_list([]);
      handle_record_performance_close();
      dispatch(dynamic_clear("createGroupParticipation"));
      dispatch(dynamic_clear("updateGroupParticipation"));
    } else if (create_error || update_error) {
      toast.error("Something went wrong");
      dispatch(dynamic_clear("createGroupParticipation"));
    }
  }, [create_success, create_error, update_success, update_error]);

  useEffect(() => {
    if (team_sent_next_level_status === "success") {
      toast.success("Team Group Participation Created Successfully");
      dispatch(dynamic_clear("sendGroupParticipationToNextLevel"));
      handle_next_level_modal_close();
    } else if (team_sent_next_level_error) {
      toast.error("Something went wrong");
      dispatch(dynamic_clear("sendGroupParticipationToNextLevel"));
    }
  }, [team_sent_next_level_status, team_sent_next_level_error]);

  const handle_record_performance = (item) => {
    set_action_item_performance(item);
    set_performance_modal_open(true);
  };

  const handle_edit = (item) => {
    console.log("item3333333333", item);

    set_action_type("edit");
    set_action_item(item);
    set_modal_open(true);
  };

  const handle_add = () => {
    set_action_type("add");
    set_rerender(!rerender);
    set_action_item({ show_error: false });
    set_modal_open(true);
  };

  const handle_modal_close = () => set_modal_open(false);

  const handle_next_level_modal_open = () => {
    set_next_level(true);
    set_action_type("add");
    set_rerender(!rerender);
    set_action_item({
      event_schedule_id: "",
      team_two_id: "",
      team_one_id: "",
      show_warning: false,
      show_error: false,
    });
  };

  const handle_next_level_modal_close = () => {
    set_action_type("close");
    set_next_level(false);
    set_action_item({});
  };

  const handle_record_performance_close = () => {
    set_action_type("close");
    set_performance_modal_open(false);
    set_action_item({});
  };

  const handle_state_level_modal_open = () => {
    set_state_level(true);
    set_action_type("add");
    set_rerender(!rerender);
    set_action_item({
      event_schedule_id: "",
      team_two_id: "",
      team_one_id: "",
      show_warning: false,
      show_error: false,
    });
  };

  const handle_state_level_modal_close = () => {
    set_action_type("close");
    set_state_level(false);
    set_action_item({});
  };

  const columns = [
    {
      title: "Team 1",
      dataIndex: "team_one",
      key: "team1",
      render: (team) => team?.name || "N/A", // Display team name
    },
    {
      title: "Team 2",
      dataIndex: "team_two",
      key: "team2",
      render: (team) => team?.name || "N/A", // Display team name
    },
    {
      title: "Performance",
      dataIndex: "group_participation_parameters",
      key: "performance",
      render: (params) => {
        if (params.length === 0) return "N/A";
        // Convert group participation parameters to a string representation
        return params
          .map((param) => `${param.event_parameter.name}: ${param.value}`)
          .join(", ");
      },
    },
    {
      title: "Winner",
      dataIndex: "winner",
      key: "winner",
      render: (winner) => (winner ? winner.name : "N/A"), // Display winner's name or "N/A"
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              icon={<AiOutlineEdit />}
              onClick={() => handle_edit(record)}
              type="link"
            />
          </Tooltip>
          <Tooltip title="Record Performance">
            <Button
              icon={<AiOutlineSetting />}
              onClick={() => handle_record_performance(record)}
              type="link"
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Row width="100%" style={{ marginTop: 10, marginBottom: 20 }}>
      <Col span={24}>
        <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col>
            <Button type="primary" onClick={handle_add}>
              Add Fixture
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={handle_next_level_modal_open}
            >
              Send to Next Level / Round
            </Button>
          </Col>
        </Row>
        <Table
          rowKey="id"
          loading={loading}
          dataSource={fixture_team_list}
          columns={columns}
          pagination={false}
          footer={() => (
            <Pagination
              showSizeChanger
              current={current_page}
              pageSize={page_limit}
              total={pagination?.total_count}
              onChange={(page, pageSize) => {
                set_current_page(page);
                set_page_limit(pageSize);
              }}
            />
          )}
        />
        <Row justify="end" style={{ marginTop: 10 }}>
          <Button
            onClick={() => navigate(-1)}
            danger
            // onClick={() => navigate.goBack()}
            style={{ width: 200 }}
          >
            Back
          </Button>
        </Row>
        <Modal
          title={
            action_item?.id && action_type === "edit"
              ? "Edit Fixture"
              : "Add Fixture"
          }
          open={is_modal_open}
          onCancel={handle_modal_close}
          footer={null}
        >
          <TeamFixtureDetails
            form={form}
            action_item={action_item}
            action_type={action_type}
          />
        </Modal>
        <Modal
          title="Record Performance"
          open={is_performance_modal_open}
          onCancel={() => set_performance_modal_open(false)}
          footer={null}
        >
          <TeamsRecordPerformance
            label={action_item_performance ? t("update") : t("add")}
            close={handle_record_performance_close}
            discipline_id={discipline_id}
            event_schedule_line_item_id={event_schedule_line_item_id}
            initial_values={action_item_performance}
            event_id={event_id}
            event_schedule_id={event_schedule_id}
            rerender={rerender}
            set_parameter_list={set_parameter_list}
            parameter_list={parameter_list}
          />
        </Modal>
        <Modal
          title="Send to Next Level / Round"
          open={next_level}
          onCancel={handle_next_level_modal_close}
          footer={null}
        >
          <SentTeamNextLevel
            event_id={event_id}
            event_schedule_id={event_schedule_id}
            event_schedule_line_item_id={event_schedule_line_item_id}
          />
          {/* Next Level modal content here */}
        </Modal>
        <Modal
          title="Send to State Level"
          open={state_level}
          onCancel={handle_state_level_modal_close}
          footer={null}
        >
          {/* State Level modal content here */}
        </Modal>
      </Col>
    </Row>
  );
};

export default FixtureTeamNextLevel;
