import React from "react";
import { Avatar, Row, Col, Space, Typography, Button } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { TrophyOutlined } from "@ant-design/icons";
import { IoMdAddCircle } from "react-icons/io";

const { Text } = Typography;

// Example result_list for demonstration
const result_list = [
  {
    id: "f9ab553c-4b37-403c-988c-bcb1f602b8f0",
    gender: "male",
    category_name: "அரசு ஊழியர் / Government Servants",
    category_id: "1bb0f269-e082-4ff3-8d2d-f2c484726365",
    event_name: "Mixed doubles",
    event_id: "5454a381-0efa-4457-9154-ee7587befd12",
    discipline_id: "2fcf480f-28a4-43c5-b465-3f4e5b4aabf7",
    event_type: "group",
    is_mixed_gender: true,
    discipline_name: "இறகுப்பந்து / Badminton",
    results: [
      {
        id: "ef9559ab-148a-4ed4-b316-76f7f19af56a",
        certificate_number: "ARR/BM/BMX-X/G/2",
        is_published: true,
        category_event_id: "f9ab553c-4b37-403c-988c-bcb1f602b8f0",
        district_id: "cc9c389e-5594-47ac-8fbd-73ca2f1cf455",
        position_id: "0e2fd423-9c33-490f-8659-702562cae60c",
        event_schedule_date: "2024-09-04T13:07:00",
        district: {
          id: "cc9c389e-5594-47ac-8fbd-73ca2f1cf455",
          name: "Ariyalur / அரியலூர்",
          code: "ARR",
        },
        team: {
          id: "abe7710a-9cac-47c4-947e-81689cea3e68",
          name: "Elango",
          code: "TEAM050297",
        },
      },
      {
        id: "1ef0ed17-7888-4e48-9baa-67d990e438d5",
        certificate_number: "ARR/BM/BMX-X/G/3",
        is_published: true,
        category_event_id: "f9ab553c-4b37-403c-988c-bcb1f602b8f0",
        district_id: "cc9c389e-5594-47ac-8fbd-73ca2f1cf455",
        position_id: "1c101b58-6919-49ac-89dc-5d761b8e4d04",
        event_schedule_date: "2024-09-04T13:07:00",
        district: {
          id: "cc9c389e-5594-47ac-8fbd-73ca2f1cf455",
          name: "Ariyalur / அரியலூர்",
          code: "ARR",
        },
        team: {
          id: "eda03e82-4937-4498-9b42-d38498aa5c2a",
          name: "anna university Ariyalur",
          code: "TEAM038833",
        },
      },
      {
        id: "77515eef-2f8f-48b2-93e4-c54eac0b89ee",
        certificate_number: "ARR/BM/BMX-X/G/1",
        is_published: true,
        category_event_id: "f9ab553c-4b37-403c-988c-bcb1f602b8f0",
        district_id: "cc9c389e-5594-47ac-8fbd-73ca2f1cf455",
        position_id: "23d922a8-927d-4999-bd41-2cff03e63656",
        event_schedule_date: "2024-09-04T13:07:00",
        district: {
          id: "cc9c389e-5594-47ac-8fbd-73ca2f1cf455",
          name: "Ariyalur / அரியலூர்",
          code: "ARR",
        },
        team: {
          id: "515d4699-dc2a-4f57-b69c-e20c98f722c2",
          name: "Shuttle thamarai",
          code: "TEAM051120",
        },
      },
    ],
  },
];

const ResultEvents = () => {
  // Helper function to format gender text
  const capitalizeFirstLowercaseRest = (text) =>
    text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

  // Helper function to generate a code
  const generateCode = (
    district_id,
    category_id,
    discipline_id,
    event_id,
    gender,
    event_type,
    category_event_id,
    position_id
  ) => {
    return `${district_id}-${category_id}-${discipline_id}-${event_id}-${gender}-${event_type}-${category_event_id}-${position_id}`;
  };

  // Updated on_click function to accept parameters
  const on_click = (
    district_id,
    category_id,
    discipline_id,
    event_id,
    gender,
    event_type,
    category_event_id,
    position_id
  ) => {
    const code = generateCode(
      district_id,
      category_id,
      discipline_id,
      event_id,
      gender,
      event_type,
      category_event_id,
      position_id
    );
    console.log("district_id:", district_id);
    console.log("category_id:", category_id);
    console.log("discipline_id:", discipline_id);
    console.log("event_ide:", event_id);
    console.log("gender:", gender);
    console.log("category_event_id:", event_type);
    console.log("category_event_id:", category_event_id);
    console.log("position_id:", position_id);
    // Call openModel or perform other actions here based on the generated code
  };

  const on_add_click = (
    district_id,
    category_id,
    discipline_id,
    event_id,
    gender,
    event_type,
    category_event_id,
    position_id
  ) => {
    const code = generateCode(
      district_id,
      category_id,
      discipline_id,
      event_id,
      gender,
      event_type,
      category_event_id,
      position_id
    );
    console.log("district_id:", district_id);
    console.log("category_id:", category_id);
    console.log("discipline_id:", discipline_id);
    console.log("event_ide:", event_id);
    console.log("gender:", gender);
    console.log("category_event_id:", event_type);
    console.log("category_event_id:", category_event_id);
    console.log("position_id:", position_id);

    // Call openModel or perform other actions here based on the generated code
  };

  return (
    <div className="event-container">
      {result_list.map((event) => {
        // Fetching the first result's district name for display
        const firstResultDistrictName =
          event.results && event.results.length > 0
            ? event.results[0]?.district?.name || "Unknown District"
            : "Unknown District";

        return (
          <div key={event.id}>
            <div className="event-header">
              <Text strong className="event-title">
                {firstResultDistrictName} - {event.discipline_name} -{" "}
                {event.category_name} -{" "}
                {event.is_mixed_gender
                  ? "Mixed"
                  : capitalizeFirstLowercaseRest(event.gender)}{" "}
                - {event.event_name}
              </Text>
            </div>
            <div className="event-results">
              {event.results?.map((result) => (
                <div key={result.id} className="result-card">
                  <Row gutter={16} align="middle" className="result-row">
                    <Col className="avatar-col">
                      <Avatar
                        className="avatar"
                        src={
                          result.player?.photo_url ||
                          "https://sdat-cmtrophy-assets-new.s3.ap-south-1.amazonaws.com/user.png"
                        }
                      />
                    </Col>

                    <Col className="team-info-col">
                      <Space direction="vertical" size={0}>
                        <Text strong className="team-name">
                          {result.team?.name ||
                            result.player?.name ||
                            "No Name"}
                        </Text>
                        <Text type="secondary" className="team-code">
                          {result.team?.code ||
                            result.player?.code ||
                            "No Code"}
                        </Text>
                      </Space>
                      {result.id ? (
                        <Button
                          icon={<AiOutlineEdit size={20} />}
                          type="link"
                          onClick={() =>
                            on_add_click(
                              result.district?.id,
                              result.results?.category_event_id,
                              result.results?.position_id,
                              event.category_id,
                              event.discipline_id,
                              event.event_id,
                              event.gender,
                              event.event_type
                            )
                          }
                        />
                      ) : (
                        <Button
                          icon={<IoMdAddCircle size={20} />}
                          type="link"
                          onClick={() =>
                            on_click(
                              result.district?.id,
                              result.results?.category_event_id,
                              result.results?.position_id,
                              event.category_id,
                              event.discipline_id,
                              event.event_id,
                              event.gender,
                              event.event_type
                            )
                          }
                        />
                      )}
                    </Col>

                    <Col className="trophy-col">
                      <TrophyOutlined className="trophy-icon" />
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ResultEvents;
