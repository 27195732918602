export const ProjectName = "CM Trophy 2024";

export const colorCode = [
  "linear-gradient(226deg, rgb(67, 229, 149) 0%, rgb(61, 178, 183) 82%)",
  "linear-gradient(48deg, rgba(204, 131, 217, 0.84) 0%, rgb(149, 38, 203) 74%)",
  "linear-gradient(48deg, rgba(3, 219, 241, 0.67) 0%, rgba(63, 81, 181, 0.92) 74%)",
  "linear-gradient(224deg, rgb(247, 151, 30), rgb(255, 210, 0))",
  "linear-gradient(220deg, rgb(234, 83, 88) 12%, rgb(250, 230, 183) 100%)",
];

export const event_round_options = [
  {
    id: "6355d8a0-95ea-4907-b704-954328fc2aae",
    name: "1",
  },
  {
    id: "878e7f14-cf31-4c25-b552-51f849559931",
    name: "2",
  },
  {
    id: "f9a80587-15bf-4616-a231-cc1409ae6ad1",
    name: "3",
  },
  {
    id: "7b4f2904-59da-4633-835f-5c9cdf39a4bf",
    name: "4",
  },
  {
    id: "17f44030-55fd-48f6-92fd-21f5713bacd9",
    name: "5",
  },
];
export const gender_options = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const participant_options = [
  {
    label: "Participant",
    value: true,
  },
  {
    label: "Not Participant",
    value: false,
  },
];
