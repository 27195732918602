import React, { useEffect, useState } from "react";
import { Button, Form, Select } from "antd";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { useAPIRequest } from "@helpers/hooks";
import {
  get_categories_query,
  get_events_schedule_query,
  get_players_without_pagination_query,
} from "@services/graphql";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { participant_options } from "@helpers/constants";
import { retrieve_item } from "@helpers/functions";
import toast from "react-hot-toast";
import {
  callLambda,
  handle_player_report,
} from "@views/components/report/report_function";

const { Option } = Select;

const PlayerListFilters = ({ form, search_value }) => {
  const { items: discipline_list } = useDynamicSelector(
    "get_disciplines_schedule"
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: district_list } = useDynamicSelector("get_districts");

  const { items: event_schedule_list } = useDynamicSelector(
    "get_events_schedule"
  );
  const { items: category_list } = useDynamicSelector("get_categories");
  const {
    items: player_list,
    loading: player_list_loading,
    status: player_list_status,
  } = useDynamicSelector("get_players_without_pagination");

  const get_categories_request = useAPIRequest(
    "get_categories",
    get_categories_query
  );
  const get_players_without_pagination_query_request = useAPIRequest(
    "get_players_without_pagination",
    get_players_without_pagination_query
  );
  const { id } = useParams();
  const name = retrieve_item("name");
  const team_details = useDynamicSelector("getTeam");
  const filter_category_id = Form.useWatch("category", form);
  const filter_discipline_id = Form.useWatch("discipline", form);
  const filter_event_id = Form.useWatch("event", form);
  const filter_gender = Form.useWatch("gender", form);
  const filter_district = Form.useWatch("district", form);
  const is_participated = Form.useWatch("is_participated", form);
  const [report_loading, set_report_loading] = useState(false);
  const [players_items, set_players_items] = useState([]);
  useEffect(() => {
    get_categories_request({}, true);
  }, []);

  const get_events_schedule_request = useAPIRequest(
    "get_events_schedule",
    get_events_schedule_query
  );

  const on_values_change = (val, values) => {
    if (values?.category && values?.gender) {
      get_events_schedule_request(
        {
          discipline_id: values?.discipline,
          gender: values?.gender,
          category_id: values?.category,
        },
        true
      );
    }
  };

  const district_options = district_list?.map((district) => ({
    value: district.id,
    label: district.name,
  }));
  console.log("player_list", player_list);

  // const handle_report = () => {
  //   if (player_list) {
  //     let inputFormat = [];
  //     player_list?.map((x, index) => {
  //       inputFormat?.push({
  //         SNo: index + 1,
  //         Name: x?.name,
  //         "Player Code": x?.player_code,
  //         "Aadhar No": x?.aadhar_no,
  //         "Date of Birth": x?.dob,
  //         District: x?.district,
  //         Category: x?.category,
  //         Discipline: x?.disciplines || "N/A",
  //         Event: x?.events || "N/A",
  //         Gender: startCase(x?.gender),
  //       });
  //     });

  //     const worksheet = XLSX.utils.json_to_sheet(inputFormat);

  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //     XLSX.writeFile(
  //       workbook,
  //       `${team_details?.name || ""}players_report.xlsx`
  //     );
  //   }
  // };

  useEffect(() => {
    if (player_list_status === "success") {
      // handle_report();
      toast.success("Report request submitted successfully");
      dispatch(dynamic_clear("get_players_without_pagination"));
    }
  }, [player_list_status]);

  useEffect(() => {
    if (players_items.length > 0) {
      handle_player_report(players_items);
    }
  }, [players_items]);

  const handle_fetch_report = async () => {
    set_report_loading(true);
    const result = await callLambda({
      request: "get_all_player_report",
      args: {
        search_string: search_value,
        category_id: filter_category_id,
        discipline_id: filter_discipline_id,
        event_id: filter_event_id,
        gender: filter_gender,
        district_id: filter_district,
        team_id: id,
        is_participated: is_participated,
      },
    });
    set_report_loading(false);
    console.log("result", JSON.parse(result.Payload).items);
    set_players_items(JSON.parse(result.Payload)?.items || []);
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        style={{ display: "flex", flexDirection: "row", gap: "10px" }}
        onValuesChange={on_values_change}
      >
        <Button
          type="primary"
          onClick={handle_fetch_report}
          loading={report_loading}
        >
          {t("export")}
        </Button>
        {!id && (
          <Form.Item name="is_participated">
            <Select
              showSearch
              placeholder="Status"
              optionFilterProp="label"
              options={participant_options}
              style={{ width: 200 }}
              allowClear
            />
          </Form.Item>
        )}
        {name === "Admin" && (
          <Form.Item name="district">
            <Select
              showSearch
              placeholder="District"
              optionFilterProp="label"
              options={district_options}
              style={{ width: 200 }}
              allowClear
            />
          </Form.Item>
        )}
        <Form.Item name="category">
          <Select placeholder="Category" allowClear>
            {category_list?.map((category, index) => (
              <Option value={category?.id} key={index}>
                {category?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="gender">
          <Select placeholder="Gender" allowClear>
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
          </Select>
        </Form.Item>

        <Form.Item name="discipline">
          <Select placeholder="Discipline" allowClear>
            {discipline_list?.map((discipline, index) => (
              <Option value={discipline?.id} key={index}>
                {discipline?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="event">
          <Select placeholder="Event">
            {event_schedule_list?.map((event, index) => (
              <Option value={event?.id} key={index}>
                {event?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PlayerListFilters;
