// import { InvokeCommand, LambdaClient } from "@aws-sdk/client-lambda";
import { get_uuid } from "@helpers/functions";
import { startCase } from "lodash";
import moment from "moment";
import * as XLSX from "xlsx";
import AWS from 'aws-sdk';

export const handle_player_report = (record) => {
  let inputFormat = [];
  record?.map((x, index) => {
    inputFormat?.push({
      SNo: index + 1,
      Name: x?.name,
      "Player Code": x?.player_code,
      "Aadhar No": x?.aadhar_no,
      "Date of Birth": moment(new Date(x?.dob)).format("DD/MM/YYYY"),
      Mobile:x.mobile,
      District: x?.district,
      Category: x?.category,
      Discipline: x?.disciplines || "N/A",
      Event: x?.events || "N/A",
      Gender: startCase(x?.gender),
    });
  });
  const worksheet = XLSX.utils.json_to_sheet(inputFormat);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `Players Report.xlsx`);
};

export const handle_team_report = (teams) => {
  let inputFormat = [];

  teams?.forEach((team, index) => {
    const teamPlayers = team.players.map((player) => ({
      Name: player.name,
      "Player Code": player.player_code,
      "Aadhar No": player.aadhar_no,
      "Date of Birth": moment(new Date(player.dob)).format("DD/MM/YYYY"),
      Mobile: player.mobile,
    }));

    // Construct the team entry with player details
    inputFormat.push({
      SNo: index + 1,
      "Team Name": team.name,
      "Team Code": team.team_code,
      District: team.district?.name,
      Category: team.category?.name,
      Discipline: team.discipline?.name,
      Event: team.event?.name,
      Gender: startCase(team.gender),
      Players: teamPlayers.map((player) => player.Name).join(", "),
      // Optionally, you could include more player details here
    });
  });

  const worksheet = XLSX.utils.json_to_sheet(inputFormat);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Teams Report");
  XLSX.writeFile(workbook, `teams_report.xlsx`);
};

export const formatData = (data) => {
  return data.map(({ error, ...rest }) => {
    return Object.keys(rest).reduce((acc, key) => {
      const formattedKey = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
      acc[formattedKey] = rest[key];
      return acc;
    }, {});
  });
};
export const team_wise_report = async (record, type, set_state) => {
  if (record && record?.length > 0) {
    const formattedData = await formatData(record);
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Event Report");
    // XLSX.writeFile(workbook, "CategoryWiseDisciplineWiseReport.xlsx");
    set_state([]);
    XLSX.writeFile(workbook, `${type}.xlsx`);
  }
};

export async function callLambda(payload) {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_LAMBDA_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_LAMBDA_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_LAMBDA_REGION,
  });
  const lambda = new AWS.Lambda();
  const params = {
    FunctionName: process.env.REACT_APP_FUNCTION_NAME, 
    Payload: JSON.stringify(payload),
  };
  try {
    const data = await lambda.invoke(params).promise();
    return data;
  } catch (error) {
    console.error('Error invoking Lambda function:', error);
    throw error;
  }
}