import { retrieve_item } from "@helpers/functions";
import { useAPIRequest } from "@helpers/hooks";
import {
  get_disciplines_schedule_query,
  get_districts_query,
  get_player_query,
  get_players_query,
} from "@services/graphql";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import Loader from "@views/components/common/loader";
import PlayerListFilters from "@views/components/player_list/player_list_filters.jsx/player_list_filters";
import CategoryWiseDisciplineWiseReport from "@views/components/update_form/category_wise_discipline_wise_report";
import DistrictWiseCategoryWiseReport from "@views/components/update_form/districtwise_categorywise_report";
import UpdateModal from "@views/components/update_form/update_modal";
import {
  Table,
  Spin,
  Pagination,
  Button,
  Input,
  Form,
  Col,
  Row,
  Tag,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import { startCase } from "lodash";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { update_many_player_participant } from "@services/graphql/player";
import ParticipantPlayerWiseReport from "@views/components/update_form/participant_player_wise_report";

const RegisteredPlayerList = () => {
  const { t } = useTranslation();
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [player_id, set_player_id] = useState("");
  const [is_update_modal_open, set_is_update_modal_open] = useState(false);
  const [search_value, set_search_value] = useState("");
  const [selected_teams_id, set_selected_teams_id] = useState([]);
  const district_id = retrieve_item("district_id");
  const { Search } = Input;
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const filter_category_id = Form.useWatch("category", form);
  const filter_discipline_id = Form.useWatch("discipline", form);
  const filter_event_id = Form.useWatch("event", form);
  const filter_gender = Form.useWatch("gender", form);
  const filter_district = Form.useWatch("district", form);
  const is_participated = Form.useWatch("is_participated", form);

  const { items, loading, pagination } = useDynamicSelector("get_players");
  let get_players_request = useAPIRequest("get_players", get_players_query);

  const { items: district_list } = useDynamicSelector("get_districts");
  let get_district_list = useAPIRequest("get_districts", get_districts_query);
  const get_player_request = useAPIRequest("get_player", get_player_query);
  const {
    status: participant_approve_status,
    loading: participant_approve_loading,
    error: participant_approve_error,
  } = useDynamicSelector("updateManyPlayersParticipation");
  const mutation_update_team_request = useAPIRequest(
    "updateManyPlayersParticipation",
    update_many_player_participant
  );
  useEffect(() => {
    get_district_list({}, true);
  }, []);

  const get_disciplines_schedule_request = useAPIRequest(
    "get_disciplines_schedule",
    get_disciplines_schedule_query
  );

  const handle_row_change = (record) => {
    set_selected_teams_id(record);
  };
  useEffect(() => {
    if (filter_gender && filter_category_id) {
      get_disciplines_schedule_request(
        {
          gender: filter_gender,
          category_id: filter_category_id,
          // differently_abled_id: schedule_differently_abled,
        },
        true
      );
    }
  }, [filter_gender, filter_category_id]);

  const get_player_list = (value) => {
    get_players_request(
      {
        page_number: value ? 1 : page_number,
        page_limit: page_limit,
        search_string: value,
        category_id: filter_category_id,
        discipline_id: filter_discipline_id,
        event_id: filter_event_id,
        gender: filter_gender,
        district_id: filter_district,
        is_participated: is_participated,
      },
      true
    );
  };

  useEffect(() => {
    get_player_list();
  }, [
    page_number,
    page_limit,
    filter_discipline_id,
    filter_event_id,
    filter_gender,
    filter_category_id,
    filter_district,
    is_participated,
  ]);

  const districtMap = district_list?.reduce((acc, district) => {
    acc[district.id] = district.name;
    return acc;
  }, {});

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => {
        return (page_number - 1) * page_limit + index + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date of birth",
      dataIndex: "dob",
      key: "dob",
      render: (dob) => (dob ? moment(dob).format("DD-MM-YYYY") : "-"),
    },

    {
      title: t("mobile"),
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Discipline",
      dataIndex: "discipline",
      key: "discipline",
    },

    {
      title: "Events",
      dataIndex: "events",
      key: "events",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (gender) => startCase(gender),
    },
    {
      title: "Player Code",
      dataIndex: "player_code",
      key: "player_code",
    },
    {
      title: "Status",
      dataIndex: "is_participated",
      key: "is_participated",
      render: (record) =>
        record ? (
          <Tag style={{ backgroundColor: "green", color: "white" }}>
            Participated
          </Tag>
        ) : (
          <Tag style={{ backgroundColor: "#C7253E", color: "white" }}>
            Not Participated
          </Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "key",
      key: "key",
      render: (key, record) => (
        <Button
          onClick={() => handle_update_click(key, record)}
          style={{
            padding: "5px 10px",
            backgroundColor: "#105d6f",
            color: "#fff",
            border: "none",
            borderRadius: "3px",
            cursor: "pointer",
          }}
        >
          <AiOutlineEdit />
        </Button>
      ),
    },
  ];

  const dataSource = items?.map((item) => ({
    key: item.id,
    name: item.name,
    dob: item.dob,
    mobile: item.mobile,
    aadhar_no: item.aadhar_no,
    district_id: item.district_id,
    player_code: item.player_code,
    gender: item.gender,
    district: item.district?.name,
    category: item.category?.name || "-",
    discipline:
      item.disciplines?.map((discipline) => discipline?.name).join(", ") || "-",
    events: item.events?.map((event) => event.name).join(", ") || "-",
    is_participated: item?.is_participated,
  }));

  const fetchData = (val, value) => {
    set_page_number(val);
    set_page_limit(value);
  };

  const handle_update_click = (val) => {
    set_player_id(val);
    get_player_request({ id: val }, true);
    set_is_update_modal_open(true);
  };
  const on_search = (value) => {
    get_player_list(value);
  };
  const handle_approve = () => {
    mutation_update_team_request({
      ids_list: selected_teams_id,
      is_participated: true,
    });
  };
  const handle_reject = () => {
    mutation_update_team_request({
      ids_list: selected_teams_id,
      is_participated: false,
    });
  };

  useEffect(() => {
    if (participant_approve_error) {
      dispatch(dynamic_clear("updateManyPlayersParticipation"));
    } else if (participant_approve_status === "success") {
      toast.success(t("participant_marked_successfully"));
      get_player_list();
      set_selected_teams_id([]);
      dispatch(dynamic_clear("updateManyPlayersParticipation"));
    }
  }, [participant_approve_error, participant_approve_status]);

  return (
    <div className="dashboard-page-main-container">
      <div>
        <div>
          <h2 className="registered_player_title">
            {t("registered_player_list")}
          </h2>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          gap: "15px",
          width: "100%",
          marginTop: "20px",
        }}
      >
        {/* <DateWiseFilter /> */}
        <PlayerListFilters form={form} search_value={search_value} />
        <Search
          className="search-input-player-list"
          placeholder="Search"
          onSearch={on_search}
          value={search_value}
          onChange={(e) => set_search_value(e.target.value)}
          allowClear
          style={{
            width: 200,
          }}
        />
      </div>

      {loading ? (
        <div
          style={{
            minHeight: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div>
          {loading ? (
            <Spin size="large" />
          ) : (
            <>
              <Row justify={"end"} gutter={10}>
                <DistrictWiseCategoryWiseReport />
                <CategoryWiseDisciplineWiseReport />
                {!district_id && <ParticipantPlayerWiseReport />}

                <Col>
                  <Button
                    type={"primary"}
                    disabled={selected_teams_id?.length > 0 ? false : true}
                    onClick={handle_approve}
                  >
                    {t("participant")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    disabled={selected_teams_id?.length > 0 ? false : true}
                    onClick={handle_reject}
                    danger
                  >
                    {t("not_participant")}
                  </Button>
                </Col>
              </Row>
              <Table
                className="custom-table-header"
                columns={columns}
                dataSource={dataSource}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "even-row" : ""
                }
                pagination={false}
                bordered={true}
                rowKey={"key"}
                style={{ marginTop: "20px" }}
                rowSelection={{ type: "checkbox", onChange: handle_row_change }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <Pagination
                  total={pagination?.total_count}
                  showSizeChanger={true}
                  current={page_number}
                  hideOnSinglePage={false}
                  onChange={fetchData}
                />
              </div>
            </>
          )}
          <UpdateModal
            is_modal_open={is_update_modal_open}
            player_id={player_id}
            set_is_update_modal_open={set_is_update_modal_open}
            set_player_id={set_player_id}
            get_player_list={get_player_list}
          />
        </div>
        // </div>
      )}
    </div>
  );
};

export default RegisteredPlayerList;
