import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Pagination,
  Modal,
  Col,
  Row,
  Divider,
  Form,
  Spin,
} from "antd";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { useTranslation } from "react-i18next";
import { get_individual_fixture_query } from "@services/graphql/performance";
import { useAPIRequest } from "@helpers/hooks";
import ParticipantsDetails from "./participant_details";
import { CiCircleRemove } from "react-icons/ci";
import { FcPositiveDynamic } from "react-icons/fc";
import { AiOutlineSetting } from "react-icons/ai";
import { retrieve_item } from "@helpers/functions";
import { useNavigate } from "react-router-dom";
import PerformanceDetails from "./performance_details";
import SendTONextLevelDetails from "./next_level_details";
import RemoveFromThisLevel from "./remove_from_this_level";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const TableListForIndividual = (props) => {
  const { event_schedule_line_item_id, discipline_id, event_id, schedule, id } =
    props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [current, set_current] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [is_single, set_is_single] = useState(false);

  console.log("event_schedule_line_item_id", event_schedule_line_item_id);

  const [next_level_rerender, set_next_rerender] = useState(false);
  const [modal_open, set_modal_open] = useState(false);
  const [performance_modal_open, set_performance_modal_open] = useState(false);
  const [action_item, set_action_item] = useState({});
  const [participants_modal, set_participants_modal] = useState(false);
  const [state_level, set_state_level] = useState(false);
  const [remove_bulk_modal, set_remove_bulk_modal] = useState(false);
  const [already_selected_rows, set_already_selected_rows] = useState([]);
  const [rerender, set_rerender] = useState(false);

  const [next_level, set_next_level] = useState(false);
  const [remove_level, set_remove_level] = useState(false);

  const roles = retrieve_item("role");

  const {
    loading: individual_table_loading,
    items,
    pagination,
  } = useDynamicSelector("getIndividualParticipations");
  const { status: update_success, error: update_error } = useDynamicSelector(
    "updateIndividualParticipation"
  );
  console.log("individual_table_loading", individual_table_loading);

  useEffect(() => {
    if (update_success === "success") {
      toast.success("Performance Updated Successfully ");
      handle_performance_modal_close();
      dispatch(dynamic_clear("updateIndividualParticipation"));
      get_individual_fixture_list();
    } else if (update_error) {
      toast.error("Something Went Wrong");
      dispatch(dynamic_clear("updateIndividualParticipation"));
    }
  }, [update_success, update_error]);

  const { player_ids_list } = useDynamicSelector("get_registered_players");

  const get_individual_fixture_request = useAPIRequest(
    "getIndividualParticipations",
    get_individual_fixture_query
  );

  const get_individual_fixture_list = () => {
    get_individual_fixture_request(
      {
        json: {
          event_schedule_line_item_id: event_schedule_line_item_id,
          page_number: current,
          page_limit: page_limit,
          search_string: "",
        },
      },
      true
    );
  };

  useEffect(() => {
    get_individual_fixture_list();
  }, [event_schedule_line_item_id, page_limit, current]);

  const handle_participants_modal_close = () => {
    set_participants_modal(false);
  };

  const handle_add_participants = () => {
    set_rerender(!rerender);
    set_participants_modal(true);
  };

  const handle_remove_bulk_modal_open = () => {
    set_remove_bulk_modal(true);
  };

  const handle_next_level_modal_open = (item, is_single) => {
    set_rerender(!rerender);
    set_next_level(true);
    set_action_item(item);
    set_is_single(is_single);
  };

  const handle_add = (item) => {
    set_rerender(!rerender);
    set_action_item(item);
    set_modal_open(true);
  };

  const handle_remove_level_modal_open = (item) => {
    set_rerender(!rerender);
    set_remove_level(true);
    set_action_item(item);
  };

  const handle_remove_level_modal_close = () => {
    set_remove_level(false);
  };

  const handle_performance_modal_open = (item) => {
    set_rerender(!rerender);
    set_action_item(item);
    set_modal_open(true);
    set_performance_modal_open(true);
  };

  const handle_performance_modal_close = () => {
    set_performance_modal_open(false);
  };

  const handle_next_level_modal_close = () => {
    set_next_level(false);
  };

  const handle_remove_bulk_modal_close = () => {
    set_remove_bulk_modal(false);
  };

  const format_player_code = (value) => {
    return value?.player?.player_code;
  };

  const format_player_name = (value) => {
    return value.player?.name;
  };

  const format_position = (value) => {
    return value?.position?.name;
  };

  const format_parameter_list = (value) => {
    let temp = "";
    temp = value?.individual_participation_parameters?.map((x, i) => {
      return `${x.event_parameter?.name} - ${x.value}`;
    });
    return <>{temp?.join(", ")}</>;
  };

  const columns = [
    {
      title: t("Player Name"),
      dataIndex: "",
      key: "name",
      render: format_player_name,
      flex: 1,
    },
    {
      title: t("Player Code"),
      dataIndex: "",
      key: "code",
      render: format_player_code,
      flex: 1,
    },
    {
      title: t("Position"),
      dataIndex: "",
      key: "position",
      render: format_position,
      flex: 1,
    },
    {
      title: t("Performance"),
      dataIndex: "",
      key: "performance",
      render: format_parameter_list,
      flex: 1,
    },
    {
      title: t("Actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 200,
      render: (record) => {
        console.log("roles", roles);

        return (
          <Row gutter={16}>
            <Col flex={1} style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="link"
                onClick={() => handle_performance_modal_open(record)}
                icon={<AiOutlineSetting color="#158d94" size="20" />}
              />
            </Col>
            <Col>
              <Button
                type="link"
                onClick={() => handle_next_level_modal_open(record, true)}
                disabled={
                  (roles?.[0]?.name === "District" &&
                    player_ids_list?.length === 1) ||
                  (roles?.[0]?.name === "Zone" && player_ids_list?.length === 3)
                }
                icon={<FcPositiveDynamic color="#158d94" size="20" />}
              />
            </Col>
            {/* {roles?.[0]?.name === "Admin" && ( */}
            <Col flex={1} style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="link"
                onClick={() => handle_remove_level_modal_open(record)}
                icon={<CiCircleRemove color="#dc3545" size="20" />}
              />
            </Col>
            {/* )} */}
          </Row>
        );
      },
    },
  ];

  const fetch_data = (page, page_size) => {
    set_current(page);
    set_page_limit(page_size);
  };

  return (
    <>
      <div style={{ padding: "20px", width: "100%" }}>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}
        >
          <Button type="primary" onClick={handle_add_participants}>
            {t("Add Participants")}
          </Button>
          {/* {roles === "Officer" && (
            <Button
              danger
              onClick={handle_remove_bulk_modal_open}
              disabled={!already_selected_rows?.length}
            >
              {t("Remove From this Level")}
            </Button>
          )} */}
          <Button type="default" onClick={() => navigate(-1)}>
            {t("Back")}
          </Button>
        </div>

        <div
          style={{
            marginTop: 10,
          }}
        >
          {individual_table_loading ? (
            <Spin size="large" />
          ) : (
            <Table
              rowKey="id"
              loading={individual_table_loading ? <Spin size="large" /> : ""}
              dataSource={items}
              columns={columns}
              pagination={false}
              rowSelection={{
                selectedRowKeys: already_selected_rows,
                onChange: (selected_row_keys) => {
                  set_already_selected_rows(selected_row_keys);
                },
                getCheckboxProps: (record) => ({
                  disabled: record.is_send_to_next_level,
                }),
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Pagination
              total={pagination?.total_count}
              showSizeChanger
              current={current}
              onChange={fetch_data}
            />
          </div>
        </div>
      </div>
      <Modal
        title={action_item ? t("Add Participants") : t("Add Participants")}
        open={participants_modal}
        onCancel={handle_participants_modal_close}
        footer={null} // We use null here to avoid having default footer buttons
        width={1000} // Adjusting the width to 1000px
      >
        <ParticipantsDetails
          label={action_item ? t("Update") : t("Submit")}
          close={handle_participants_modal_close}
          disciplineId={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          initialValues={action_item}
          event_id={event_id}
          schedule={schedule}
          playerId={already_selected_rows}
          participantList={items}
          id={id}
          rerender={rerender}
        />
      </Modal>
      <Modal
        title={
          <>
            <span
              style={{
                fontSize: 20,
              }}
            >
              {t("Performance")}
            </span>
            <Divider />
          </>
        }
        open={performance_modal_open}
        onCancel={handle_performance_modal_close}
        footer={null} // We use null here to avoid having default footer buttons
        width={600} // Adjusting the width to 1000px
      >
        <PerformanceDetails
          label={action_item.id ? t("update") : t("add")}
          close={handle_performance_modal_close}
          initialValues={action_item}
          eventScheduleLineItemId={event_schedule_line_item_id}
          rerender={rerender}
        />
      </Modal>

      <Modal
        title={
          <>
            <span
              style={{
                fontSize: 20,
              }}
            >
              {t("Send to Next Level")}
            </span>
            <Divider />
          </>
        }
        visible={next_level}
        onCancel={handle_next_level_modal_close}
        footer={null} // We use null here to avoid having default footer buttons
        width={600} // Adjusting the width to 1000px
      >
        <SendTONextLevelDetails
          label={action_item ? t("update") : t("Submit")}
          close={handle_next_level_modal_close}
          disciplineId={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          initialValues={action_item}
          eventId={event_id}
          schedule={schedule}
          player_id={already_selected_rows}
          isSingle={is_single}
          event_schedule_id={id}
          rerender={rerender}
        />
      </Modal>

      <Modal
        title={
          <>
            <span
              style={{
                fontSize: 20,
              }}
            >
              {t("Remove From this Level")}
            </span>
            <Divider />
          </>
        }
        open={remove_level}
        onCancel={handle_remove_level_modal_close}
        footer={null} // We use null here to avoid having default footer buttons
        width={600} // Adjusting the width to 1000px
      >
        <RemoveFromThisLevel
          label={action_item ? t("update") : t("Submit")}
          close={handle_remove_level_modal_close}
          disciplineId={discipline_id}
          eventScheduleLineItemId={event_schedule_line_item_id}
          initialValues={action_item}
          eventId={event_id}
          schedule={schedule}
          playerId={already_selected_rows}
          rerender={rerender}
        />
      </Modal>
    </>
  );
};

export default TableListForIndividual;
